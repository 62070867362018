import React, { FC, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setName, setResultMsg, setIsSubmitDisabled, createPlaylist } from "../viewModel/playlistSlice";
import "./CreatePlaylistView.css";
import ModalProps from "../util/ModalProps";

const CreatePlaylistView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { name, isSubmitDisabled, resultMsg, isPlaylistCreated } = useSelector((state: RootState) => state.playlist);

  useEffect(() => {
    if (isPlaylistCreated) {
      setTimeout(() => {
        dispatch(setName(""));
        dispatch(setIsSubmitDisabled(true));
        dispatch(setResultMsg(""));
      }
      , 100);
    }
  }
  , [isPlaylistCreated]);


  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setName(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Name cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onClickCreate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(createPlaylist());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
        <div>
            <label>Provide a name for the playlist</label>
            <input type="text" value={name} onChange={onChangeName} />
          </div>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickCreate}
            >
              Create
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default CreatePlaylistView;
