import { handleError } from "../util/error_handler";


export async function getAllPlaylists() {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/all';
  try {

    const response = await fetch(url, {
        method : 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    });
    if (!response.ok) {
      handleError({response});
    }
    return await response.json();
  } catch (error) {
    console.log('Failed to fetch playlists', error);
    throw new Error('Failed to fetch playlists');
  }
}

export async function getOwnPlaylists() {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/own';
  try {

    const response = await fetch(url, {
        method : 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    });
    if (!response.ok) {
      handleError({response});
    }
    return await response.json();
  } catch (error) {
    console.log('Failed to fetch playlists', error);
    throw new Error('Failed to fetch playlists');
  }
}

export async function createPlaylistModel(
    name: string,
    dispatch: any
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/create';
    const payload = {name};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response, dispatch});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to create playlist', error);
    throw new Error('Failed to create playlist');
  }
}

export async function viewMediaListModel(
    playlistId: number,
    dispatch: any
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/view';
    const payload = {playlistId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response, dispatch});
    }
    return await response.json();
  } catch (error) {
    console.log('Failed to view playlist', error);
    throw new Error('Failed to view playlist');
  }
}

export async function getPlaylistByIdModel(
    playlistId: number
    ): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/one';
    const payload = {playlistId};
  try {
      
      const response = await fetch(url, {
          method : 'POST',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
          body: JSON.stringify(payload),
      });
      if (!response.ok) {
        handleError({response});
      }
      return await response.json();
    } catch (error) {
      console.log('Failed to fetch playlist', error);
      throw new Error('Failed to fetch playlist');
    }
}
