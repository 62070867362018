import { handleError } from "../util/error_handler";

export async function manageModel(
    playlistId: number,
    dispatch: any
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/manage';
    const payload = {playlistId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response, dispatch});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to fetch manage details', error);
    throw new Error('Failed to fetch manage details');
  }
}

export async function getComplementMediaListModel(
    playlistId: number,
    dispatch: any
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/complement';
    const payload = {playlistId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response, dispatch});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to fetch complement media list', error);
    throw new Error('Failed to fetch complement media list');
  }
}

export async function sharePlaylistModel(
    playlistId: number,
    userId: number,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/share';
    const payload = {playlistId, userId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to share playlist', error);
    throw new Error('Failed to share playlist');
  }
}

export async function unsharePlaylistModel(
    playlistId: number,
    userId: number,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/unshare';
    const payload = {playlistId, userId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to unshare playlist', error);
    throw new Error('Failed to unshare playlist');
  }
}

export async function addMediaModel(
    playlistId: number,
    mediaId: number,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/addMedia';
    const payload = {playlistId, mediaId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to add media', error);
    throw new Error('Failed to add media');
  }
}

export async function removeMediaModel(
    playlistId: number,
    mediaId: number,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/removeMedia';
    const payload = {playlistId, mediaId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to remove media', error);
    throw new Error('Failed to remove media');
  }
}

export async function updatePlaylistModel(
    id: number,
    name: string,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/update';
    const payload = {id, name};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to update playlist', error);
    throw new Error('Failed to update playlist');
  }
}

export async function deletePlaylistModel(
    playlistId: number,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/playlist/delete';
    const payload = {playlistId};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to delete playlist', error);
    throw new Error('Failed to delete playlist');
  }
}

export async function updateMediaModel(
    id: number,
    title: string,
    artist: string,
    path: string,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/media/update';
    const payload = {id, title, artist, path};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to update media', error);
    throw new Error('Failed to update media');
  }
}

export async function deleteMediaModel(
    id: number,
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/media/delete';
    const payload = {id};
  try {

    const response = await fetch(url, {
        method : 'POST',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to delete media', error);
    throw new Error('Failed to delete media');
  }
}