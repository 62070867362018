import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { sortingAllMediaReducer, isManageDataLoadedReducer, getManageDataReducer, sortingSharedReducer, sortingUnsharedReducer, setPlaylistIdReducer, shareMediaReducer, unshareMediaReducer, removeMediaFromPlaylistReducer, setUserIdToShareReducer, setUserIdToUnshareReducer, setMediaIdReducer, setMediaIdToAddReducer, getComplementMediaListReducer, sortComplementMediaListReducer, isComplementMediaListLoadedReducer, addMediaToPlaylistReducer, setMediaIdToRemoveReducer, setPlaylistNewNameReducer, setIsSubmitDisabledReducer, setResultsMsgReducer, updatePlaylistReducer, deletePlaylistReducer, updateMediaReducer, setOwnMediaIdReducer, setOwnMediaTitleReducer, setOwnMediaArtistReducer, setOwnMediaPathReducer, deleteMediaReducer } from './manageReducer';
import { manageModel, sharePlaylistModel, unsharePlaylistModel, removeMediaModel, getComplementMediaListModel, addMediaModel, updatePlaylistModel, deletePlaylistModel, updateMediaModel, deleteMediaModel } from '../model/manageModel';


export interface ManageState {
    playlistId: number;
    mediaId: number;
    mediaIdToAdd: number;
    mediaIdToRemove: number;
    ownMediaId: number;
    ownMediaTitle: string;
    ownMediaArtist: string;
    ownMediaPath: string;
    playlistNewName: string;
    complementMediaList: [
        {
            id: number;
            userId: number;
            artist: string;
            title: string;
            path: string;
        },
    ];
    // new approach
    data:
        {
            playlist: {
                id: number;
                userId: number;
                name: string;
            },
            mediaList: {
                medias: [
                    {
                        id: number;
                        userId: number;
                        artist: string;
                        title: string;
                        path: string;
                    },
                ]
            }
            unsharedUsers: {
                users: [
                    {
                        id: number;
                        firstName: string;
                        lastName: string;
                        email: string;
                        password: string;
                    },
                ]
            },
            sharedUsers: {
                users: [
                    {
                        id: number;
                        firstName: string;
                        lastName: string;
                        email: string;
                        password: string;
                    },
                ]
            }
        }
    ;

    isManageDataLoaded: boolean;
    isComplementMediaListLoaded: boolean;
    mediaListSorting: 'a-z';
    complementMediaListSorting: 'a-z';
    sharedUsersSorting: 'a-z';
    unsharedUsersSorting: 'a-z';
    resultMsg: string; // is not in use at the moment
    error: string[];
    isSubmitDisabled: boolean; // is not in use at the moment
    userIdToShare: number;
    userIdToUnshare: number;
    isMediaUpdated: boolean;
    isMediaDeleted: boolean;
    isMediaAdded: boolean;
    isMediaRemoved: boolean;
    isMediaShared: boolean;
    isMediaUnshared: boolean;
    isPlaylistUpdated: boolean;
    isPlaylistDeleted: boolean;
}

const initialState: ManageState = {
    playlistId: 0,
    mediaId: 0,
    mediaIdToAdd: 0,
    mediaIdToRemove: 0,
    ownMediaId: 0,
    ownMediaTitle: '',
    ownMediaArtist: '',
    ownMediaPath: '',
    playlistNewName: '',
    complementMediaList: [
        {
            id: 0,
            userId: 0,
            artist: '',
            title: '',
            path: '',
        },
    ],
    data:
        {
            playlist: {
                id: 0,
                userId: 0,
                name: '',
            },
            mediaList: {
                medias: [
                    {
                        id: 0,
                        userId: 0,
                        artist: '',
                        title: '',
                        path: '',
                    },
                ]
            },
            unsharedUsers: {
                users: [
                    {
                        id: 0,
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                    },
                ]
            },
            sharedUsers: {
                users: [
                    {
                        id: 0,
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                    },
                ]
            }
        },
    isManageDataLoaded: false,
    isComplementMediaListLoaded: false,
    mediaListSorting: 'a-z',
    complementMediaListSorting: 'a-z',
    sharedUsersSorting: 'a-z',
    unsharedUsersSorting: 'a-z',
    resultMsg: '',
    error: [],
    isSubmitDisabled: false,
    userIdToShare: 0,
    userIdToUnshare: 0,
    isMediaUpdated: false,
    isMediaDeleted: false,
    isMediaAdded: false,
    isMediaRemoved: false,
    isMediaShared: false,
    isMediaUnshared: false,
    isPlaylistUpdated: false,
    isPlaylistDeleted: false,
};

export const ManageSlice = createSlice({
    name: 'playlist',
    initialState,
    reducers: {
        setPlaylistId: setPlaylistIdReducer,
        setMediaId: setMediaIdReducer,
        setManageDataLoaded: isManageDataLoadedReducer,
        setComplementMediaListLoaded: isComplementMediaListLoadedReducer,
        setMediaSorting: sortingAllMediaReducer,
        setComplementMediaListSorting: sortComplementMediaListReducer,
        setSharedUsersSorting: sortingSharedReducer,
        setUnsharedUsersSorting: sortingUnsharedReducer,
        setUserIdToShare: setUserIdToShareReducer,
        setUserIdToUnshare: setUserIdToUnshareReducer,
        setMediaIdToAdd: setMediaIdToAddReducer,
        setMediaIdToRemove: setMediaIdToRemoveReducer,
        setPlaylistNewName: setPlaylistNewNameReducer,
        setIsSubmitDisabled: setIsSubmitDisabledReducer,
        setResultMsg: setResultsMsgReducer,
        setOwnMediaId: setOwnMediaIdReducer,
        setOwnMediaTitle: setOwnMediaTitleReducer,
        setOwnMediaArtist: setOwnMediaArtistReducer,
        setOwnMediaPath: setOwnMediaPathReducer,
    },
    extraReducers: (builder) => {
        builder.addCase(loadManageData.fulfilled, getManageDataReducer);
        builder.addCase(sharePlaylist.fulfilled, shareMediaReducer);
        builder.addCase(unsharePlaylist.fulfilled, unshareMediaReducer);
        builder.addCase(removeMedia.fulfilled, removeMediaFromPlaylistReducer);
        builder.addCase(loadComplementMediaList.fulfilled, getComplementMediaListReducer);
        builder.addCase(addMedia.fulfilled, addMediaToPlaylistReducer);
        builder.addCase(updatePlaylist.fulfilled, updatePlaylistReducer);
        builder.addCase(deletePlaylist.fulfilled, deletePlaylistReducer);
        builder.addCase(updateMedia.fulfilled, updateMediaReducer);
        builder.addCase(deleteMedia.fulfilled, deleteMediaReducer);
    },
});



export const loadManageData = createAsyncThunk(
    'playlist/manage',
    async (arg, {getState, dispatch}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId} = state.manage;
        if (state.manage.error.length === 0) {
            return await manageModel(
                playlistId,
                dispatch
            );
        } else return false;
    }
);

export const loadComplementMediaList = createAsyncThunk(
    'playlist/complement',
    async (arg, {getState, dispatch}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId} = state.manage;
        if (state.manage.error.length === 0) {
            return await getComplementMediaListModel(
                playlistId,
                dispatch
            );
        } else return false;
    }
);

export const sharePlaylist = createAsyncThunk(
    'playlist/share',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId, userIdToShare} = state.manage;
        if (state.manage.error.length === 0) {
            return await sharePlaylistModel(
                playlistId,
                userIdToShare,
            );
        } else return false;
    }
);

export const unsharePlaylist = createAsyncThunk(
    'playlist/unshare',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId, userIdToUnshare} = state.manage;
        if (state.manage.error.length === 0) {
            return await unsharePlaylistModel(
                playlistId,
                userIdToUnshare,
            );
        } else return false;
    }
);

export const addMedia = createAsyncThunk(
    'playlist/addMedia',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId, mediaIdToAdd} = state.manage;
        if (state.manage.error.length === 0) {
            return await addMediaModel(
                playlistId,
                mediaIdToAdd,
            );
        } else return false;
    }
);

export const removeMedia = createAsyncThunk(
    'playlist/remove',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId, mediaIdToRemove} = state.manage;
        if (state.manage.error.length === 0) {
            return await removeMediaModel(
                playlistId,
                mediaIdToRemove,
            );
        } else return false;
    }
);

export const updatePlaylist = createAsyncThunk(
    'playlist/update',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId, playlistNewName} = state.manage;
        if (state.manage.error.length === 0) {
            return await updatePlaylistModel(
                playlistId,
                playlistNewName,
            );
        } else return false;
    }
);

export const deletePlaylist = createAsyncThunk(
    'playlist/delete',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {playlistId} = state.manage;
        if (state.manage.error.length === 0) {
            return await deletePlaylistModel(
                playlistId,
            );
        } else return false;
    }
);

export const updateMedia = createAsyncThunk(
    'media/update',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {ownMediaId, ownMediaTitle, ownMediaArtist, ownMediaPath} = state.manage;
        if (state.manage.error.length === 0) {
            return await updateMediaModel(
                ownMediaId,
                ownMediaTitle,
                ownMediaArtist,
                ownMediaPath,
            );
        } else return false;
    }
);

export const deleteMedia = createAsyncThunk(
    'media/delete',
    async (arg, {getState}) => {
        const state = getState() as {manage: ManageState};
        const {ownMediaId} = state.manage;
        if (state.manage.error.length === 0) {
            return await deleteMediaModel(
                ownMediaId,
            );
        } else return false;
    }
);

export const { setPlaylistId, setMediaId, setManageDataLoaded, setMediaSorting, setSharedUsersSorting, setUnsharedUsersSorting, setUserIdToShare, setUserIdToUnshare, setMediaIdToAdd, setComplementMediaListSorting, setComplementMediaListLoaded, setMediaIdToRemove, setPlaylistNewName, setIsSubmitDisabled, setResultMsg, setOwnMediaId, setOwnMediaTitle, setOwnMediaArtist, setOwnMediaPath } = ManageSlice.actions;
export default ManageSlice.reducer;
