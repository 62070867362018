import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faRegistered,
  faSignInAlt,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Registration from "./RegistrationView";
import "./SideBarMenusView.css";
import LoginView from "./LoginView";
import Logout from "./LogoutView";
import {useSelector } from "react-redux";
import { RootState} from "../util/store";
import { Link } from "react-router-dom";
import { Trigger } from "../util/component_trigger";

const SideBarMenusView = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const {isLoggedIn, userProfile, isProfileLoaded, } = useSelector((state: RootState) => state.user);
  const {userProfileTrigger} = Trigger();

  useEffect(() => {
    userProfileTrigger();
  }, [isLoggedIn]);


  const onClickToggleRegister = () => {
    setShowRegister(!showRegister);
  };

  const onClickToggleLogin = () => {
    setShowLogin(!showLogin);
  };

  const onClickToggleLogout = () => {
    setShowLogout(!showLogout);
  };

  return (
    <React.Fragment>
      <ul>
        {(isLoggedIn && isProfileLoaded) ? (
          <li>
            <FontAwesomeIcon icon={faUser} />
            <span className="menu-name">
              <Link to="/profile">{userProfile.firstName + ' ' + userProfile.lastName}</Link>
            </span>
          </li>
        ) : null}
        {isLoggedIn ? false : (
          <li>
            <FontAwesomeIcon icon={faRegistered} />
            <span onClick={onClickToggleRegister} className="menu-name">
              register
            </span>
            <Registration
              isOpen={showRegister}
              onClickToggle={onClickToggleRegister}
            />
          </li>
        )}

        {isLoggedIn ? false : (
          <li>
            <FontAwesomeIcon icon={faSignInAlt} />
            <span onClick={onClickToggleLogin} className="menu-name">
              login
            </span>
            <LoginView isOpen={showLogin} onClickToggle={onClickToggleLogin} />
          </li>
        )}

        {isLoggedIn ? (
          <li>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span onClick={onClickToggleLogout} className="menu-name">
              logout
            </span>
            <Logout isOpen={showLogout} onClickToggle={onClickToggleLogout} />
          </li>
        ) : null}
      </ul>
    </React.Fragment>
  );
};

export default SideBarMenusView;