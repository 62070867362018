import { PayloadAction } from "@reduxjs/toolkit";
import { PlaylistsState } from "./playlistSlice";

export const playlistIdReducer = (
    state: PlaylistsState,
    action: PayloadAction<number>
) => {
    state.playlistId = action.payload;
}

export const playlistNameReducer = (
    state: PlaylistsState,
    action: PayloadAction<string>
) => {
    state.name = action.payload;
};

export const isPlaylistLoadedReducer = (
    state: PlaylistsState,
    action: PayloadAction<boolean>
) => {
    state.isPlaylistLoaded = action.payload;
}

export const isMediaLoadedReducer = (
    state: PlaylistsState,
    action: PayloadAction<boolean>
) => {
    state.isMediaInPlaylistLoaded = action.payload;
}

export const sortingAllPlaylistsReducer = (
    state: PlaylistsState,
    action: PayloadAction<'a-z'>
) => {
   if(Array.isArray(state.allPlaylists)) {
       state.allPlaylists.sort((a, b) => {
           const nameA = a.name.toUpperCase();
           const nameB = b.name.toUpperCase();
           if (nameA < nameB) {
               return -1;
           }
           if (nameA > nameB) {
               return 1;
           }
           return 0;
       });
       state.sorting = action.payload;
   } else {
         console.error('state.allPlaylists is not an array');
    }
};

export const sortingOwnPlaylistsReducer = (
    state: PlaylistsState,
    action: PayloadAction<'a-z'>
) => {
    state.ownPlaylists.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
   });
   state.sorting = action.payload;
}

export const isLoadedReducer = (
    state: PlaylistsState,
    action: PayloadAction<boolean>
) => {
    state.isLoaded = action.payload;
};

export const getAllReducer = (
    state: PlaylistsState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.allPlaylists = action.payload.data[0].playlists;
        state.users = action.payload.data.users;
    } else {
        state.error = action.payload.message;
    }
};

export const getOwnReducer = (
    state: PlaylistsState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.ownPlaylists = action.payload.data[0].playlists;
    } else {
        state.error = action.payload.message;
    }
};

export const createPlaylistReducer = (state: PlaylistsState, action: any) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.resultMsg = action.payload.data;
        state.isPlaylistCreated = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const resultMsgReducer = (
    state: PlaylistsState,
    action: PayloadAction<string>
  ) => {
    state.resultMsg = action.payload;
};

export const isSubmitDisabledReducer = (
    state: PlaylistsState,
    action: PayloadAction<boolean>
  ) => {
    state.isSubmitDisabled = action.payload;
  };

export const loadMediaListReducer = (state: PlaylistsState, action: any) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.mediaList = action.payload.data[0].medias;
    } else {
        state.error = action.payload.message;
    }
}

export const sortMediaListReducer = (
    state: PlaylistsState,
    action: PayloadAction<'a-z'>
) => {
    if (Array.isArray(state.mediaList)) {
        state.mediaList.sort((a, b) => {
            const nameA = a.title.toUpperCase();
            const nameB = b.title.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        state.mediaInPlaylistSorting = action.payload;
    }
}

export const getPlaylistByIdReducer = (state: PlaylistsState, action: any) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.playlist = action.payload.data[0];
    } else {
        state.error = action.payload.message;
    }
}