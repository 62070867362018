import {handleError} from '../util/error_handler';

async function registrationModel(
  firstName: string,
  lastName: string,
  password: string,
  email: string,
  dispatch: any
): Promise<any> {
  const host: string =
    process.env.REACT_APP_SERVER_URL || 'http://localhost:3001';
  const url: string = '' + host + '/user/register'; // API endpoint
  const payload = {
    firstName,
    lastName,
    password,
    email,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      handleError({response, dispatch});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.error('Fetch operation failed:', error);
    throw new Error('Registration failed');
  }
}

export {registrationModel};
