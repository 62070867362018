import React, { FC, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setPlaylistNewName, setResultMsg, setIsSubmitDisabled, updatePlaylist } from "../viewModel/manageSlice";
import "./UpdatePlaylistView.css";
import ModalProps from "../util/ModalProps";

const UpdatePlaylistView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { playlistNewName, isSubmitDisabled, resultMsg, isPlaylistUpdated } = useSelector((state: RootState) => state.manage);

  useEffect(() => {
    if (isPlaylistUpdated) {
      setTimeout(() => {
        dispatch(setResultMsg(""));
      }, 500);
    }
  }
  , [isPlaylistUpdated, dispatch]);

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPlaylistNewName(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Name cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onClickUpdate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(updatePlaylist());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
        <div>
            <label>Provide a new name for the playlist</label>
            <input type="text" value={playlistNewName} onChange={onChangeName} />
          </div>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickUpdate}
            >
              Update
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default UpdatePlaylistView;
