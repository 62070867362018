import {configureStore} from '@reduxjs/toolkit';
import userReducer from '../viewModel/userSlice';
import playlistReducer from '../viewModel/playlistSlice';
import mediaReducer from '../viewModel/mediaSlice';
import manageReducer from '../viewModel/manageSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    playlist: playlistReducer,
    media: mediaReducer,
    manage: manageReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
