import React, { useState } from "react";
import "./HeaderView.css";
import { Link } from "react-router-dom";

const HeaderView = () => {


  return (
    <React.Fragment>
      <nav>
        <Link to="/">
        <strong>Thaikosha Music</strong>
        </Link>
      </nav>
    </React.Fragment>
  );
};

export default HeaderView;
