import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
  emailReducer,
  firstnameReducer,
  lastnameReducer,
  passwordReducer,
  loginReducer,
  passwordConfirmReducer,
  registerReducer,
  errorReducer,
  isSubmitDisabledReducer,
  resultMsgReducer,
  logoutReducer,
  getUserReducer,
  isLoadedReducer,
  updateUserReducer,
  uploadAvatarReducer,
  fileReducer,
  deleteUserReducer
} from './userReducers';
import { loginModel } from '../model/loginModel';
import {registrationModel} from '../model/registrationModel';
import {logoutModel} from '../model/logoutModel';
import { getUser, updateUserModel, uploadAvatarModel, deleteUserModel } from '../model/profileModel';

/**
 * Interface defining the fields of the User state.
 * */
export interface UserState {
  password: string;
  passwordConfirm: string;
  firstname: string;
  lastname: string;
  email: string;
  userInfo: {userId: string;} | null;
  error: string[];
  backendError: string[];
  isLoggedIn: boolean;
  resultMsg: string;
  isSubmitDisabled: boolean;
  userProfile: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  isProfileLoaded: boolean;
  file: File | null;
  isRegistrationSuccess: boolean;
  isDeleteSuccess: boolean;
  isUpdateSuccess: boolean;
}

/**
 * The initial state of the user.
 * */
const initialState: UserState = {
  password: '',
  passwordConfirm: '',
  firstname: '',
  lastname: '',
  email: '',
  userInfo: null,
  error: [],
  backendError: [],
  isLoggedIn: false,
  resultMsg: '',
  isSubmitDisabled: true,
  userProfile: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  }, 
  isProfileLoaded: false,
  file: null,
  isRegistrationSuccess: false,
  isDeleteSuccess: false,
  isUpdateSuccess: false,
};

/**
 * Creates the user slice by defining the name of the user slice, its initial state,
 * and the reducers that can change that state.
 * */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setPassword: passwordReducer,
    setPasswordConfirm: passwordConfirmReducer,
    setFirstname: firstnameReducer,
    setLastname: lastnameReducer,
    setEmail: emailReducer,
    setBackendError: errorReducer,
    setIsSubmitDisabled: isSubmitDisabledReducer,
    setResultMsg: resultMsgReducer,
    setIsProfileLoaded: isLoadedReducer,
    setFile: fileReducer,
  },
  extraReducers: builder => {
    builder
      .addCase(login.fulfilled, loginReducer)
      .addCase(register.fulfilled, registerReducer)
      .addCase(logout.fulfilled, logoutReducer)
      .addCase(loadUser.fulfilled, getUserReducer)
      .addCase(updateUser.fulfilled, updateUserReducer)
      .addCase(uploadAvatar.fulfilled, uploadAvatarReducer)
      .addCase(deleteUser.fulfilled, deleteUserReducer);
  },
});


/**
 * Used for logging in a user by making a call to the login model.
 * */
export const login = createAsyncThunk(
  'user/login',
  async (arg, {getState, dispatch}) => {
    const state = getState() as {user: UserState};
    if (state.user.error.length === 0) {
      return await loginModel(
        state.user.email,
        state.user.password,
        dispatch
      );
    } else return false;
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async (arg, {dispatch}) => {
    return await logoutModel(dispatch);
  }
);

export const register = createAsyncThunk(
  'user/register',
  async (arg, {getState, dispatch}) => {
    const state = getState() as {user: UserState};
    if (state.user.error.length === 0) {
      return await registrationModel(
        state.user.firstname,
        state.user.lastname,
        state.user.password,
        state.user.email,
        dispatch
      );
    } else return false;
  }
);

export const loadUser = createAsyncThunk(
  'user/profile',
  async () => {
    return await getUser();
  }
);

export const updateUser = createAsyncThunk(
  'user/update',
  async (arg, {getState, dispatch}) => {
    const state = getState() as {user: UserState};
    return await updateUserModel(
      state.user.firstname,
      state.user.lastname,
      state.user.email,
      state.user.password,
      dispatch
    );
  }
);

export const uploadAvatar = createAsyncThunk(
  'user/avatar',
  async (arg, {getState, dispatch}) => {
    const state = getState() as {user: UserState};
    const {file} = state.user;
    if(file && state.user.error.length === 0) {
      return await uploadAvatarModel(
        file, dispatch
        );
    } else return false;
  }
);

export const deleteUser = createAsyncThunk(
  'user/delete',
  async (arg, {dispatch}) => {
    return await deleteUserModel();
  }
);

export const {
  setPassword,
  setFirstname,
  setLastname,
  setEmail,
  setPasswordConfirm,
  setBackendError,
  setIsSubmitDisabled,
  setResultMsg,
  setIsProfileLoaded,
  setFile,
} = userSlice.actions;

/**
 * Export the reducer function as the default export.
 */
export default userSlice.reducer;
