import React, { FC, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setTitle, setArtist, setFile, setResultMsg, setIsSubmitDisabled, addMedia } from "../viewModel/mediaSlice";
import "./AddMediaView.css";
import ModalProps from "../util/ModalProps";

const AddMediaView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { title, artist, file, isSubmitDisabled, resultMsg, isMediaAdded } = useSelector((state: RootState) => state.media);

  useEffect(() => {
    if (isMediaAdded) {
      setTimeout(() => {
        dispatch(setResultMsg(""));
        dispatch(setIsSubmitDisabled(true));
        dispatch(setTitle(""));
        dispatch(setArtist(""));
        dispatch(setFile({} as File)); // Fix: Pass an empty file object instead of null
      }, 500);
    }
  }
  , [isMediaAdded, dispatch]);

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTitle(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Title cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onChangeArtist = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setArtist(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Artist cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      dispatch(setFile(e.target.files[0]));
      dispatch(setIsSubmitDisabled(false));
    }
    else {
        dispatch(setIsSubmitDisabled(true));
        dispatch(setResultMsg("Please select a file"));
    }
  };

  const onClickCreate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(addMedia());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
            <div>
                <label>Title</label>
                <input type="text" value={title} onChange={onChangeTitle} />
          </div>
        </div>
        <div className="reg-inputs">
            <div>
                <label>Artist</label>
                <input type="text" value={artist} onChange={onChangeArtist} />
            </div>
        </div>
        <div className="reg-inputs">
            <div>
                <div>Media File</div>
                <input type="file" className="form-btn-right" onChange={onChangeFile} />
            </div>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickCreate}
            >
              Insert
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default AddMediaView;
