import React, { FC, useEffect } from "react";
import Select from 'react-select';
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setMediaIdToAdd, addMedia, setResultMsg } from "../viewModel/manageSlice";
import { Trigger } from "../util/component_trigger";
import "./AddMediaToPlaylistView.css";
import ModalProps from "../util/ModalProps";

const AddMediaToPlaylistView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const { resultMsg, complementMediaList, isComplementMediaListLoaded, mediaIdToAdd, isMediaAdded } = useSelector((state: RootState) => state.manage);

  useEffect(() => {
    if (isMediaAdded) {
      setTimeout(() => {
        dispatch(setResultMsg(""));
      }, 500);
    }
  }
  , [isMediaAdded]);

  const { complementMediaListTrigger } = Trigger();
  useEffect(() => {
    complementMediaListTrigger();
  }, [isLoggedIn]);

  let safeComplementMediaList: any[] = [];
  const complementMedia = complementMediaList ? complementMediaList : null;
  if (complementMedia) {
    safeComplementMediaList = complementMedia ? complementMedia : [];
  }

  const addOptions = safeComplementMediaList.map(media => ({ value: media.id, label: `${media.title} | ${media.artist}` }));

  const onAdd = async (e: React.FormEvent) => {
    e.preventDefault();
    if (mediaIdToAdd) {
      dispatch(addMedia());
      dispatch(setMediaIdToAdd(0));
    }
  };

  const handleAddChange = (selectedOption: any) => {
    dispatch(setMediaIdToAdd(selectedOption ? selectedOption.value : 0));
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      {isComplementMediaListLoaded && (
        <div>
          <div>
                <label>Select Media</label>
          </div>
          <Select
            options={addOptions}
            onChange={handleAddChange}
            value={addOptions.find(option => option.value === mediaIdToAdd)}
            isClearable
            placeholder="Select User to Share..."
          />
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={!mediaIdToAdd}
              onClick={onAdd}
            >
              Add
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      )}
    </ReactModal>
  );
};

export default AddMediaToPlaylistView;
