import React, { FC, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setEmail, setFirstname, setLastname, logout, register, setIsSubmitDisabled, setResultMsg } from "../viewModel/userSlice";
import "./RegistrationView.css";
import ModalProps from "../util/ModalProps";
import PasswordComparison from "../util/PasswordComparison";
import { useNavigate } from "react-router-dom";
import { errorPlacer } from "../util/error_handler";

const RegistrationView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { firstname, lastname, email, password, passwordConfirm, isSubmitDisabled, resultMsg, backendError, isRegistrationSuccess, isLoggedIn } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRegistrationSuccess) {
      console.log("Registration successful");
      setTimeout(() => {
        dispatch(setResultMsg(""));
        dispatch(setIsSubmitDisabled(true));
        dispatch(setFirstname(""));
        dispatch(setLastname(""));
        dispatch(setEmail(""));
        dispatch(setIsSubmitDisabled(true));
        dispatch(setResultMsg(""));
        window.location.href = '/';
      }
        , 1000);
    }
  }, [isRegistrationSuccess]);

  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFirstname(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("First name cannot be empty"));
    }
      
    else dispatch(setIsSubmitDisabled(false));
  };
  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLastname(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Last name cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Email cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onClickRegister = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(register());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
        <div>
            <label>first name</label>
            <input type="text" value={firstname} onChange={onChangeFirstName} />
          </div>
          <strong>
            {errorPlacer('firstName', backendError)}
          </strong>
          <div>
            <label>last name</label>
            <input type="text" value={lastname} onChange={onChangeLastName} />
          </div>
          <strong>
            {errorPlacer('lastName', backendError)}
          </strong>
          <div>
            <label>email</label>
            <input type="text" value={email} onChange={onChangeEmail} />
          </div>
          <strong>
            {errorPlacer('email', backendError)}
          </strong>
          <div>
            <PasswordComparison
              dispatch={dispatch}
              password={password}
              passwordConfirm={passwordConfirm}
            />
          </div>
          <strong>
            {errorPlacer('password', backendError)}
          </strong>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickRegister}
            >
              Register
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default RegistrationView;
