import {PayloadAction} from '@reduxjs/toolkit';
import { UserState } from './userSlice';

export const passwordReducer = (
  state: UserState,
  action: PayloadAction<string>
) => {
  state.password = action.payload;
};
export const passwordConfirmReducer = (
  state: any,
  action: PayloadAction<string>
) => {
  state.passwordConfirm = action.payload;
};
export const firstnameReducer = (
  state: UserState,
  action: PayloadAction<string>
) => {
  state.firstname = action.payload;
};
export const lastnameReducer = (
  state: UserState,
  action: PayloadAction<string>
) => {
  state.lastname = action.payload;
};
export const emailReducer = (
  state: UserState,
  action: PayloadAction<string>
) => {
  state.email = action.payload;
};
export const errorReducer = (state: any, action: PayloadAction<string[]>) => {
  state.backendError = [...action.payload];
};
export const isSubmitDisabledReducer = (
  state: UserState,
  action: PayloadAction<boolean>
) => {
  state.isSubmitDisabled = action.payload;
};
export const resultMsgReducer = (
  state: UserState,
  action: PayloadAction<string>
) => {
  state.resultMsg = action.payload;
};
export const fileReducer = (
  state: UserState,
  action: PayloadAction<File>
) => {
  state.file = action.payload;
}

/**
 * Extra reducer for handling the fulfilled login action.
 * @param {Object} state - The current state.
 * @param {Object} action - The dispatched action.
 * @param {boolean} action.payload.success - The success status of the login operation.
 * @param {string} action.payload.token - The token received upon successful login.
 * @param {string} action.payload.error - The error message, if any.
 */
export const loginReducer = (state: UserState, action: any) => {
  if (!action.payload) return;
  if (action.payload.success) {
    state.isLoggedIn = true;
    state.resultMsg = action.payload.data[0].message;
  } else {
    state.error = [action.payload.error.message];
  }
};
export const logoutReducer = (state: UserState, action: any) => {
  if (!action.payload) return;
  if (action.payload.success) {
    state.isLoggedIn = false;
  } else {
    state.error = [action.payload.error.message];
  }
}
export const registerReducer = (state: UserState, action: any) => {
  if (!action.payload) return;
  if (action.payload.success) {
    state.resultMsg = action.payload.data;
    state.isRegistrationSuccess = true;
  } else {
    state.error = [action.payload.error.message];
  }
};

export const getUserReducer = (state: UserState, action: any) => {
  if (!action.payload) return;
  if (action.payload.success) {
    state.userProfile = action.payload.data[0];
  } else {
    state.error = [action.payload.error.message];
  }
}

export const isLoadedReducer = (state: UserState, action: PayloadAction<boolean>) => {
  state.isProfileLoaded = action.payload;
}

export const updateUserReducer = (state: UserState, action: any) => {
  if (!action.payload) return;
  if (action.payload.success) {
    state.resultMsg = action.payload.data;
    state.isUpdateSuccess = true;
  } else {
    state.error = [action.payload.error.message];
  }
}

export const uploadAvatarReducer = (state: UserState, action: any) => {
  if (!action.payload) return;
  if (action.payload.success) {
    state.resultMsg = action.payload.data;
  } else {
    state.error = [action.payload.error.message];
  }
}

export const deleteUserReducer = (state: UserState, action: any) => {
  if (!action.payload) return;
  if (action.payload.success) {
    state.resultMsg = action.payload.data;
    state.isDeleteSuccess = true;
  } else {
    state.error = [action.payload.error.message];
  }
}