import React, { FC } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setFile, setResultMsg, setIsSubmitDisabled, uploadAvatar } from "../viewModel/userSlice";
import "./AddAvatarView.css";
import ModalProps from "../util/ModalProps";

const AddAvatarView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { file, isSubmitDisabled, resultMsg } = useSelector((state: RootState) => state.user);

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      dispatch(setFile(e.target.files[0]));
      dispatch(setIsSubmitDisabled(false));
    }
    else {
        dispatch(setIsSubmitDisabled(true));
        dispatch(setResultMsg("Please select a file"));
    }
  };

  const onClickUpload = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(uploadAvatar());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
            <div>
                <div>Media File</div>
                <input type="file" className="form-btn-right" onChange={onChangeFile} />
            </div>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickUpload}
            >
              Upload
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default AddAvatarView;
