import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./store";

const ProtectedRoute = ({component: Component, ...rest}: {component: React.ComponentType<any>},) => {
    const {isLoggedIn} = useSelector((state: RootState) => state.user);
    if(!isLoggedIn) {
        return <Navigate to="/" />;
    }
    return <Component {...rest} />;
}

export default ProtectedRoute;