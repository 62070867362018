import React, { FC } from "react";
import NavView from "./NavView";
import MainView from "./MainView";
import "./HomeView.css";
import SideBarView from "./SideBarView";
import LeftMenuView from "./LeftMenuView";

const HomeView: FC = () => {
  return (
    <div className="screen-root-container home-container">
      <div className="navigation">
        <NavView />
      </div>
      <SideBarView />
      <LeftMenuView />
      <MainView />
    </div>
  );
};

export default HomeView;
