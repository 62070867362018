import { PayloadAction } from "@reduxjs/toolkit";
import { ManageState } from "./manageSlice";

export const setPlaylistIdReducer = (
    state: ManageState,
    action: PayloadAction<number>
) => {
    state.playlistId = action.payload;
};

export const setMediaIdReducer = (
    state: ManageState,
    action: PayloadAction<number>
) => {
    state.mediaId = action.payload;
};

export const setMediaIdToAddReducer = (
    state: ManageState,
    action: PayloadAction<number>
) => {
    state.mediaIdToAdd = action.payload;
}

export const setMediaIdToRemoveReducer = (
    state: ManageState,
    action: PayloadAction<number>
) => {
    state.mediaIdToRemove = action.payload;
}

export const setOwnMediaIdReducer = (
    state: ManageState,
    action: PayloadAction<number>
) => {
    state.ownMediaId = action.payload;
}

export const setOwnMediaTitleReducer = (
    state: ManageState,
    action: PayloadAction<string>
) => {
    state.ownMediaTitle = action.payload;
}

export const setOwnMediaArtistReducer = (
    state: ManageState,
    action: PayloadAction<string>
) => {
    state.ownMediaArtist = action.payload;
}

export const setOwnMediaPathReducer = (
    state: ManageState,
    action: PayloadAction<string>
) => {
    state.ownMediaPath = action.payload;
}

export const setPlaylistNewNameReducer = (
    state: ManageState,
    action: PayloadAction<string>
) => {
    state.playlistNewName = action.payload;
}

export const setResultsMsgReducer = (
    state: ManageState,
    action: PayloadAction<string>
) => {
    state.resultMsg = action.payload;
}

export const setIsSubmitDisabledReducer = (
    state: ManageState,
    action: PayloadAction<boolean>
) => {
    state.isSubmitDisabled = action.payload;
}

export const getManageDataReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.data = action.payload.data[0]; // should be updated based on the actual data structure
    } else {
        state.error = action.payload.message;
    }
}

export const getComplementMediaListReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.complementMediaList = action.payload.data; // should be updated based on the actual data structure
    } else {
        state.error = action.payload.message;
    }
}

export const isComplementMediaListLoadedReducer = (
    state: ManageState,
    action: PayloadAction<boolean>
) => {
    state.isComplementMediaListLoaded = action.payload;
}

export const sortComplementMediaListReducer = (
    state: ManageState,
    action: PayloadAction<'a-z'>
) => {
    if (Array.isArray(state.complementMediaList)) {
        state.complementMediaList.sort((a, b) => {
            const nameA = a.title.toUpperCase();
            const nameB = b.title.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        state.complementMediaListSorting = action.payload;
    }
}

export const isManageDataLoadedReducer = (
    state: ManageState,
    action: PayloadAction<boolean>
) => {
    state.isManageDataLoaded = action.payload;
}

export const sortingAllMediaReducer = (
    state: ManageState,
    action: PayloadAction<'a-z'>
) => {
    // Check if state.data.mediaList and state.data.mediaList.medias exist before accessing
    if (state.data.mediaList && Array.isArray(state.data.mediaList.medias)) {
        state.data.mediaList.medias.sort((a, b) => {
            const nameA = a.title.toUpperCase(); // Assuming 'title' exists and is a string
            const nameB = b.title.toUpperCase(); // Assuming 'title' exists and is a string
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        state.mediaListSorting = action.payload; // Update the sorting state
    }
    // No need to explicitly return; reducer will implicitly return the modified state
};


// sharedUsers
export const sortingSharedReducer = (
    state: ManageState,
    action: PayloadAction<'a-z'>
) => {
    // Ensure state.sharedUsers is an array before sorting
    if (state.data.sharedUsers && Array && Array.isArray(state.data.sharedUsers.users)) {
        state.data.sharedUsers.users.sort((a, b) => {
            const nameA = a.firstName.toUpperCase();
            const nameB = b.firstName.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        state.sharedUsersSorting = action.payload;
    } else {
        // Optionally handle the case where state.sharedUsers is not an array
        console.error('state.sharedUsers is not an array');
    }
};


// unsharedUsers
export const sortingUnsharedReducer = (
    state: ManageState,
    action: PayloadAction<'a-z'>
) => {
   if(state.data.unsharedUsers && Array && Array.isArray(state.data.unsharedUsers.users)) {
    state.data.unsharedUsers.users.sort((a, b) => {
            const nameA = a.firstName.toUpperCase();
            const nameB = b.firstName.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        state.unsharedUsersSorting = action.payload;
   }
};

// manage handlers
export const shareMediaReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.isMediaShared = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const unshareMediaReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.isMediaUnshared = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const removeMediaFromPlaylistReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.isMediaRemoved = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const setUserIdToShareReducer = (
    state: ManageState,
    action: PayloadAction<number>
) => {
    state.userIdToShare = action.payload;
}

export const setUserIdToUnshareReducer = (
    state: ManageState,
    action: PayloadAction<number>
) => {
    state.userIdToUnshare = action.payload;
    state.isMediaRemoved = true;
}

export const addMediaToPlaylistReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.resultMsg = action.payload.data;
        state.isMediaAdded = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const updatePlaylistReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.resultMsg = action.payload.data;
        state.isPlaylistUpdated = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const deletePlaylistReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.resultMsg = action.payload.data;
        state.isPlaylistDeleted = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const updateMediaReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.resultMsg = action.payload.data;
        state.isMediaUpdated = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const deleteMediaReducer = (
    state: ManageState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.resultMsg = action.payload.data;
        state.isMediaDeleted = true;
    } else {
        state.error = [action.payload.error.message];
    }
}


