import React, { FC } from "react";
import {
  isPasswordValid,
  PasswordTestResult,
} from "./PasswordValidator";
import { setPassword, setPasswordConfirm, setIsSubmitDisabled, setResultMsg } from "../viewModel/userSlice";

interface PasswordComparisonProps {
  dispatch: React.Dispatch<any>;
  password: string;
  passwordConfirm: string;
}

const PasswordComparison: FC<PasswordComparisonProps> = ({
  dispatch,
  password,
  passwordConfirm,
}) => {
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(e.target.value));
    const passwordCheck: PasswordTestResult = isPasswordValid(e.target.value);

    if (!passwordCheck.isValid) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg(passwordCheck.message));
      return;
    }
    passwordsSame(passwordConfirm, e.target.value);
  };
  const onChangePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPasswordConfirm(e.target.value));
    passwordsSame(password, e.target.value);
  };
  const passwordsSame = (passwordVal: string, passwordConfirmVal: string) => {
    if (passwordVal !== passwordConfirmVal) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Passwords do not match"));
      return false;
    } else {
      dispatch(setIsSubmitDisabled(false));
      return true;
    }
  };

  return (
    <React.Fragment>
      <div>
        <label>password</label>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={onChangePassword}
        />
      </div>
      <div>
        <label>password confirmation</label>
        <input
          type="password"
          placeholder="Password Confirmation"
          value={passwordConfirm}
          onChange={onChangePasswordConfirm}
        />
      </div>
    </React.Fragment>
  );
};

export default PasswordComparison;
