import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { getAllPlaylists } from '../model/playlistModel';
import { getOwnPlaylists } from '../model/playlistModel';
import { getAllReducer, isLoadedReducer, sortingAllPlaylistsReducer, getOwnReducer, sortingOwnPlaylistsReducer, playlistNameReducer, createPlaylistReducer, resultMsgReducer, isSubmitDisabledReducer, playlistIdReducer, loadMediaListReducer, isMediaLoadedReducer, sortMediaListReducer, isPlaylistLoadedReducer, getPlaylistByIdReducer } from './playlisReducer';
import { createPlaylistModel, viewMediaListModel, getPlaylistByIdModel } from '../model/playlistModel';

export interface PlaylistsState {
    name: string;
    playlistId: number;
    allPlaylists: any[];
    ownPlaylists: any[];
    users: any[];
    sorting: 'a-z';
    isLoaded: boolean;
    resultMsg: string;
    error: string[];
    isSubmitDisabled: boolean;
    mediaList: [{
        id: number;
        userId: number;
        artist: string;
        title: string;
        path: string;
    }];
    mediaInPlaylistSorting: 'a-z';
    isMediaInPlaylistLoaded: boolean;
    isPlaylistLoaded: boolean;
    playlist: {
        id: number;
        userId: number;
        name: string;
    };
    isPlaylistCreated: boolean;
}

const initialState: PlaylistsState = {
    playlistId: 0,
    name: '',
    allPlaylists: [],
    ownPlaylists: [],
    users: [],
    sorting: 'a-z',
    isLoaded: false,
    resultMsg: '',
    error: [],
    isSubmitDisabled: true,
    mediaList: [{
        id: 0,
        userId: 0,
        artist: '',
        title: '',
        path: '',
    }],
    mediaInPlaylistSorting: 'a-z',
    isMediaInPlaylistLoaded: false,
    isPlaylistLoaded: false,
    playlist: {
        id: 0,
        userId: 0,
        name: '',
    },
    isPlaylistCreated: false,
};

export const PlaylistSlice = createSlice({
    name: 'playlist',
    initialState,
    reducers: {
        setName: playlistNameReducer,
        setResultMsg: resultMsgReducer,
        setAllPlaylistSorting: sortingAllPlaylistsReducer,
        setAllPlaylistIsLoaded: isLoadedReducer,
        setOwnPlaylistSorting: sortingOwnPlaylistsReducer,
        setOwnPlaylistIsLoaded: isLoadedReducer,
        setIsSubmitDisabled: isSubmitDisabledReducer,
        setPlaylistId: playlistIdReducer,
        setMediaInPlaylistSorting: sortMediaListReducer,
        setIsMediaInPlaylistLoaded: isMediaLoadedReducer,
        setIsPlaylistLoadeded: isPlaylistLoadedReducer,
    },
    extraReducers: (builder) => {
        builder.addCase(loadAllPlaylists.fulfilled, getAllReducer);
        builder.addCase(loadOwnPlaylists.fulfilled, getOwnReducer);
        builder.addCase(createPlaylist.fulfilled, createPlaylistReducer);
        builder.addCase(loadMediaList.fulfilled, loadMediaListReducer);
        builder.addCase(getPlaylistById.fulfilled, getPlaylistByIdReducer);
    },
});

export const createPlaylist = createAsyncThunk(
    'playlist/create',
    async (arg, {getState, dispatch}) => {
        const state = getState() as {playlist: PlaylistsState};
        if (state.playlist.error.length === 0) {
            return await createPlaylistModel(
                state.playlist.name,
                dispatch
            );
        } else return false;
    }
);


export const loadAllPlaylists = createAsyncThunk(
    'playlist/all',
    async () => {
        return getAllPlaylists();
    }
);

export const loadOwnPlaylists = createAsyncThunk(
    'playlist/own',
    async () => {
        return getOwnPlaylists();
    }
);

export const loadMediaList = createAsyncThunk(
    'playlist/view',
    async (arg, {getState, dispatch}) => {
        const state = getState() as {playlist: PlaylistsState};
        return await viewMediaListModel(
            state.playlist.playlistId,
            dispatch
        );
    }
);

export const getPlaylistById = createAsyncThunk(
    'playlist/one',
    async (arg, {getState, dispatch}) => {
        const state = getState() as {playlist: PlaylistsState};
        return await getPlaylistByIdModel(
            state.playlist.playlistId,
        );
    }
);

export const { setAllPlaylistSorting, setOwnPlaylistSorting, setAllPlaylistIsLoaded, setOwnPlaylistIsLoaded, setName, setResultMsg, setIsSubmitDisabled, setPlaylistId, setIsMediaInPlaylistLoaded, setMediaInPlaylistSorting, setIsPlaylistLoadeded } = PlaylistSlice.actions;
export default PlaylistSlice.reducer;
