import React, { FC } from "react";
import ReactModal from "react-modal";
import ModalProps from "../util/ModalProps";
import "./LogoutView.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../util/store";
import { useNavigate } from "react-router-dom";
import { logout, setResultMsg } from "../viewModel/userSlice";

const Logout: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { isLoggedIn, resultMsg } = useSelector((state: RootState) => state.user);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!isLoggedIn) {
            console.log("Logout success");
            window.location.href = '/';
        }
      }, [isLoggedIn]);

    const onClickLogout = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        onClickToggle(e);
        dispatch(logout());
    };

    const onClickCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClickToggle(e);
    };

    return (
        <ReactModal
            className="modal-menu"
            isOpen={isOpen}
            onRequestClose={onClickToggle}
            shouldCloseOnOverlayClick={true}
        >
            <form>
                <div className="logout-inputs">
                    Are you sure you want to logout?
                </div>
                <div className="form-buttons form-buttons-sm">
                    <div className="form-btn-left">
                        <button
                            style={{ marginLeft: ".5em" }}
                            className="action-btn"
                            onClick={onClickLogout}
                        >
                        Logout
                        </button>
                        <button
                            style={{ marginLeft: ".5em" }}
                            className="cancel-btn"
                            onClick={onClickCancel}
                        >
                        Close
                        </button>
                    </div>
                    <span className="form-btn-left">
                        <strong>{resultMsg}</strong>
                    </span>
                </div>
                
            </form>
        </ReactModal>
    )
}

export default Logout;