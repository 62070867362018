import React, { FC, useEffect, useState } from "react";
import Select from 'react-select';
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../util/store";
import { useNavigate } from "react-router-dom";
import { Trigger } from "../util/component_trigger";
import AddMediaToPlaylistView from "./AddMediaToPlaylistView";
import HeaderView from "./HeaderView";
import { sharePlaylist, unsharePlaylist, removeMedia, setUserIdToShare, setUserIdToUnshare, setMediaIdToRemove, setResultMsg } from "../viewModel/manageSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./ManagePlaylistView.css";

const ManagePlaylistView: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { data, userIdToShare, userIdToUnshare, isManageDataLoaded, mediaIdToRemove, isMediaShared, isMediaUnshared, isMediaAdded, isMediaRemoved} = useSelector((state: RootState) => state.manage);
  const { isLoggedIn } = useSelector((state: RootState) => state.user);

  const [showAdd, setShowAdd] = useState(false);

  const { manageDataTrigger } = Trigger();

  useEffect(() => {
    manageDataTrigger();
    if (isMediaAdded) {
      setTimeout(() => {
        dispatch(setResultMsg(""));
      }, 500);
      console.log("isMediaShared: ", isMediaShared);
      console.log("isMediaUnshared: ", isMediaUnshared);
      console.log("isMediaRemoved: ", isMediaRemoved);
    }
  }
  , [showAdd, isMediaShared, isMediaUnshared, isMediaAdded, isMediaRemoved, dispatch]);

  let safeSharedUsers: any[] = [];
  let safeUnsharedUsers: any[] = [];
  let safeMediaList: any[] = [];
  let playlist: any = {};

  const manageData = data ? data : null;
  if (manageData) {
    // Assign values inside the if block
    safeSharedUsers = manageData.sharedUsers ? manageData.sharedUsers.users : [];
    safeUnsharedUsers = manageData.unsharedUsers ? manageData.unsharedUsers.users : [];
    safeMediaList = manageData.mediaList ? manageData.mediaList.medias : [];
    playlist = manageData.playlist ? manageData.playlist : {};
  }

  const unsharedOptions = safeUnsharedUsers.map(user => ({ value: user.id, label: `${user.firstName} ${user.lastName} | ${user.email}` }));
  const sharedOptions = safeSharedUsers.map(user => ({ value: user.id, label: `${user.firstName} ${user.lastName} | ${user.email}` }));

  const onShare = async (e: React.FormEvent) => {
    e.preventDefault();
    if (userIdToShare) {
      dispatch(sharePlaylist());
      dispatch(setUserIdToShare(0));
    }
  };

  const handleShareChange = (selectedOption: any) => {
    dispatch(setUserIdToShare(selectedOption ? selectedOption.value : 0));
  };

  const handleUnshareChange = (selectedOption: any) => {
    dispatch(setUserIdToUnshare(selectedOption ? selectedOption.value : 0));
  }

  const onUnshare = async (e: React.FormEvent) => {
    e.preventDefault();
    if (userIdToUnshare) {
      dispatch(unsharePlaylist());
      dispatch(setUserIdToUnshare(0));
    }
  };

  const handleRemoveMedia = async (mediaId: number) => {
    dispatch(setMediaIdToRemove(mediaId));
    dispatch(removeMedia());
    if(mediaIdToRemove) {
      dispatch(setMediaIdToRemove(0));
    }

  };

  const onClickToggleAdd = () => {
    setShowAdd(!showAdd);
  };

  useEffect(() => {
    //dispatch(fetchPlaylistDetails(playlist.id));
  }, [dispatch, playlist.id]);

  return (
    <div key={`${safeMediaList.length}-${isMediaAdded}-${isMediaRemoved}`}>
      <HeaderView />
    <div className="manage-playlist">
      {isManageDataLoaded && playlist && (
        <p className="playlist-info-text">
          {playlist.name}
        </p>
      )}

{isManageDataLoaded && (
        <div>
          <p className="share-unshare">Share playlist with user: </p>
          <Select
            options={unsharedOptions}
            onChange={handleShareChange}
            value={unsharedOptions.find(option => option.value === userIdToShare)}
            isClearable
            placeholder="Select User to Share..."
          />
          <button 
              style={{ marginLeft: "1em" }}
              className="action-btn" 
              onClick={onShare} disabled={!userIdToShare}>Share</button>
        </div>
      )}

{isManageDataLoaded && (
        <div>
          <p className="share-unshare">Unshare playlist with user: </p>
          <Select
            options={sharedOptions}
            onChange={handleUnshareChange}
            value={sharedOptions.find(option => option.value === userIdToUnshare)}
            isClearable
            placeholder="Select User to Unshare..."
          />
          <button 
              style={{ marginLeft: "1em" }}
              className="action-btn"
              onClick={onUnshare} disabled={!userIdToUnshare}>Unshare</button>
        </div>
      )}
      {isManageDataLoaded && safeMediaList.length > 0 && (
        <div>
          <p className="section-title">Media in Playlist</p>
        <div className="table-container">
          <table className="table">
                <thead>
                    <tr>
                    <th>Title</th>
                    <th>Artist</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {safeMediaList.map(media => (
                    <tr key={media.id}>
                        <td>{media.title}</td>
                        <td>{media.artist}</td>
                        <td>
                        <FontAwesomeIcon icon={faXmark} 
                        size="lg"
                        className="iconStyle"
                        onClick={() => handleRemoveMedia(media.id)}/>
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>
        </div>
        </div>
      )}
      {
            <div>
              <button 
                style={{ marginLeft: "1em" }}
                className="action-btn"
                onClick={onClickToggleAdd}>
                Add Media to Playlist
              </button>
              <AddMediaToPlaylistView
                isOpen={showAdd}
                onClickToggle={onClickToggleAdd}
              />
            </div>
        }
    </div>
    </div>
  );
};

export default ManagePlaylistView;