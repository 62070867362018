import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./view/HomeView";
import UserProfileView from "./view/UserProfileView";
import ManagePlaylistView from "./view/ManagePlaylistView";
import ProtectedRoute from "./util/ProtectedRoute";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route 
        path="/profile" 
        element={
          <ProtectedRoute component={UserProfileView} />
        } 
        />
      <Route 
        path="/manage" 
        element={
          <ProtectedRoute component={ManagePlaylistView} />
        } 
        />
      <Route 
        path="/playlists/:playlistId" 
        element={
          <ProtectedRoute component={Home} />
        }
        
      />
    </Routes>
  );
}

export default App;
