import React from "react";
import { useWindowDimensions } from "../util/useWindowDimensions";
import SideBarMenusView from "./SideBarMenusView";

const SideBarView = () => {
  const { width } = useWindowDimensions();

  if (width <= 768) {
    return null;
  }

  return (
    <div className="sidebar">
      <SideBarMenusView />
    </div>
  );
};

export default SideBarView;
