import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../util/store';
import { Trigger } from '../util/component_trigger';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import "./PlaylistView.css";

const PlaylistView = () => {
    const { loadMediaInPlaylistTrigger } = Trigger();
    const { isMediaInPlaylistLoaded, mediaList, playlistId } = useSelector((state: RootState) => state.playlist);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) && !navigator.platform.startsWith('Mac'));
        loadMediaInPlaylistTrigger();
        setCurrentTrackIndex(0);
    }, [playlistId]);

    const handleClickNext = () => {
        setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % mediaList.length);
    };

    const handleClickPrevious = () => {
        setCurrentTrackIndex((prevIndex) => prevIndex === 0 ? mediaList.length - 1 : prevIndex - 1);
    };

    const handleSelectTrack = (index: number) => {
        setCurrentTrackIndex(index);
    };

    const handleTrackEnd = () => {
        if (currentTrackIndex < mediaList.length - 1) {
            handleClickNext();
        }
    };

    // Placeholder for playlist owner metadata
    const playlistOwner = "Owner Name"; // Replace this with actual data

    return (
        <div className="playlist-view-container">
            {/* Header Section with Audio Player and Metadata */}
            <div className="playlist-header">
                {isMediaInPlaylistLoaded && mediaList.length > 0 && (
                    <AudioPlayer
                        autoPlay
                        volume={0.5}
                        src={mediaList[currentTrackIndex].path}
                        showSkipControls={true}
                        onClickNext={handleClickNext}
                        onClickPrevious={handleClickPrevious}
                        onEnded={handleTrackEnd}
                        onError={() => console.log('Error while playing the track')}
                        customVolumeControls={isIOS ? [] : undefined} // Hide custom volume controls on iOS
                    />
                )}
            </div>

            {/* Media List Section */}
            <div className="media-list">
                {isMediaInPlaylistLoaded && mediaList.length > 0 ? (
                    mediaList.map((media, index) => (
                        <div
                            key={media.id}
                            className={`media-item ${index === currentTrackIndex ? 'active' : ''}`}
                            onClick={() => handleSelectTrack(index)}
                        >
                            <div className="media-info">
                                <span className="media-title">{media.title}</span>
                                <span className="media-artist">{media.artist}</span>
                            </div>
                            {index === currentTrackIndex && (
                                <span className="playing-indicator">Playing</span>
                            )}
                        </div>
                    ))
                ) : (
                    <p>No media found in the playlist.</p>
                )}
            </div>
        </div>
    );
};

export default PlaylistView;
