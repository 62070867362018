import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../util/store';
import PlaylistView from "./PlaylistView";
import { useParams } from "react-router-dom";
import { setPlaylistId } from '../viewModel/playlistSlice';
import MainHeaderView from "./MainHeaderView";

const MainView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {playlistId} = useParams<{playlistId: string}>();
  const [playlistView, setPlaylistView] = useState<JSX.Element | null>(null);
  
  useEffect(() => {
    if(playlistId && parseInt(playlistId) > 0) {
      dispatch(setPlaylistId(parseInt(playlistId)));
      setPlaylistView(<PlaylistView />);
    } else {
      setPlaylistView(null);
    }
  }, [playlistId]);

  return (
    <main className="content">
      <MainHeaderView />
      {playlistView}
    </main>
  );
};

export default MainView;
