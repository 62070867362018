import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../util/store";
import { useWindowDimensions } from "../util/useWindowDimensions";
import { Trigger } from "../util/component_trigger";
import { Link } from "react-router-dom";

const LeftMenuView = () => {
    const {isLoaded, allPlaylists} = useSelector((state: RootState) => state.playlist);
    const {isLoggedIn} = useSelector((state: RootState) => state.user);
    const {width} = useWindowDimensions();
    const {allPlaylistTrigger} = Trigger();

    const [playlists, setPlaylists] = useState<JSX.Element>(<div></div>);


    useEffect(() => {
        if(isLoggedIn) {
            allPlaylistTrigger();
        } else {
            setPlaylists(<div></div>);
        }
      }, [isLoggedIn]);

    useEffect(() => {
        if(isLoaded) {
            const playlists = allPlaylists.map((playlist) => {
                return (
                    isLoggedIn && <li key={playlist.id}>
                        <Link to={`/playlists/${playlist.id}`}>
                            {playlist.name}
                        </Link>
                    </li>
                );
            });
            setPlaylists(<ul className="category">{playlists}</ul>);
        }
    }, [isLoaded, allPlaylists]);
    
    if(width <= 768) {
        return null;
    }
    return <div className="left-menu">{playlists}</div>;
};

export default LeftMenuView;