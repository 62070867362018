import React, { FC, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { deletePlaylist, setResultMsg } from "../viewModel/manageSlice";
import "./DeletePlaylistView.css";
import ModalProps from "../util/ModalProps";

const DeletePlaylistView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { resultMsg, isPlaylistDeleted } = useSelector((state: RootState) => state.manage);
  const message = "Are you sure you want to delete this playlist? This action cannot be undone and will permanently remove all associated media from the playlist.";

  useEffect(() => {
    if (isPlaylistDeleted) {
      setTimeout(() => {
        dispatch(setResultMsg(""));
      }, 500);
    }
  }, [isPlaylistDeleted, dispatch]);
  console.log("DeletePlaylistView.tsx: isPlaylistDeleted: ", isPlaylistDeleted);

  const onClickDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(deletePlaylist());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <div>
        <div className="reg-inputs">
        <p className="modal-message">{message}</p>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              onClick={onClickDelete}
            >
              Confirm
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Cancel
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </div>
    </ReactModal>
  );
};

export default DeletePlaylistView;
