import React from "react";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ErrorBoundary from "./util/ErrorBoundary";
import ReactModal from "react-modal";
import { store } from "./util/store";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      {[
        <Provider store={store} key="provider">
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>,
      
      ]}
    </ErrorBoundary>
  </React.StrictMode>
);

ReactModal.setAppElement("#root");

// Service worker registration
serviceWorker.unregister();
