import React, { FC, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setFirstname, setLastname, setResultMsg, setIsSubmitDisabled, updateUser } from "../viewModel/userSlice";
import PasswordComparison from "../util/PasswordComparison";
import "./UpdateUserProfileView.css";
import ModalProps from "../util/ModalProps";
import { useNavigate } from "react-router-dom";
import { errorPlacer } from "../util/error_handler";

const UpdateUserProfileView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { firstname, lastname, password, passwordConfirm, backendError, isSubmitDisabled, resultMsg, isUpdateSuccess } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (isUpdateSuccess) {
      setTimeout(() => {
        dispatch(setResultMsg(""));
        dispatch(setIsSubmitDisabled(true));
        dispatch(setFirstname(""));
        dispatch(setLastname(""));
        navigate("/profile");
      }
        , 1000);
    }
  }
    , [isUpdateSuccess, dispatch, navigate]);

  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFirstname(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("First name cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLastname(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Last name cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  }


  const onClickUpdate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(updateUser());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
        <div>
            <label>first name</label>
            <input type="text" value={firstname} onChange={onChangeFirstName} />
          </div>
          <strong>
            {errorPlacer('firstName', backendError)}
          </strong>
          <div>
            <label>last name</label>
            <input type="text" value={lastname} onChange={onChangeLastName} />
          </div>
          <strong>
            {errorPlacer('lastName', backendError)}
          </strong>
          <div>
            <PasswordComparison
              dispatch={dispatch}
              password={password}
              passwordConfirm={passwordConfirm}
            />
          </div>
          <strong>
            {errorPlacer('password', backendError)}
          </strong>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickUpdate}
            >
              Update
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default UpdateUserProfileView;
