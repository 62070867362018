import React, { FC, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { deleteMedia, setResultMsg } from "../viewModel/manageSlice";
import "./DeleteMediaView.css";
import ModalProps from "../util/ModalProps";

const DeleteMediaView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { resultMsg, isMediaDeleted } = useSelector((state: RootState) => state.manage);
  const message = "Are you sure you want to delete this media? This action cannot be undone and will permanently remove all associated data from the media.";

  useEffect(() => {
    setTimeout(() => {
      if (isMediaDeleted) {
        dispatch(setResultMsg(""));
      }
    }, 500);
  }
  , [isMediaDeleted, dispatch]);

  const onClickDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(deleteMedia());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <div>
        <div className="reg-inputs">
        <p className="modal-message">{message}</p>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              onClick={onClickDelete}
            >
              Confirm
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Cancel
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </div>
    </ReactModal>
  );
};

export default DeleteMediaView;
