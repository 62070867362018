import React, {FC, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState} from "../util/store";
import { Trigger } from "../util/component_trigger";
import CreatePlaylistView from "./CreatePlaylistView";
import AddMediaView from "./AddMediaView";
import UpdatePlaylistView from "./UpdatePlaylistView";
import DeletePlaylistView from "./DeletePlaylistView";
import UpdateMediaView from "./UpdateMediaView";
import DeleteMediaView from "./DeleteMediaView";
import UpdateUserProfileView from "./UpdateUserProfileView";
import AddAvatarView from "./AddAvatarView";
import DeleteUserView from "./DeleteUserView";
import { setEmail } from "../viewModel/userSlice";
import { setPlaylistId, setOwnMediaId, setOwnMediaPath } from "../viewModel/manageSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faPen,
  faTrash,
  faUserPen,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "./UserProfileView.css";
import "./FontAwesomeIcons.css"
import HeaderView from "./HeaderView";

const UserProfileView: FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {isLoaded, ownPlaylists} = useSelector((state: RootState) => state.playlist);
    const {userProfile, isProfileLoaded, isLoggedIn} = useSelector((state: RootState) => state.user);
    const [showCreate, setShowCreate] = useState(false);

    const {isMediaLoaded, allMedia} = useSelector((state: RootState) => state.media);
    const [showAdd, setShowAdd] = useState(false);

    const [showUpdate, setShowUpdate] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [showMediaUpdate, setShowMediaUpdate] = useState(false);
    const [showMediaDelete, setShowMediaDelete] = useState(false);

    const [showUserUpdate, setShowUserUpdate] = useState(false);

    const [showAddAvatar, setShowAddAvatar] = useState(false);

    const [showDeleteUser, setShowDeleteUser] = useState(false);

    const handleManage = (playlistId: number) => {
        dispatch(setPlaylistId(playlistId));
        navigate('/manage');
    }

    const {ownPlaylistTrigger, allMediaTrigger} = Trigger();

    useEffect(() => {
      ownPlaylistTrigger();
      allMediaTrigger();
    }, [showUpdate, showDelete, showAdd, showCreate, showMediaUpdate, showMediaDelete, showUserUpdate, showAddAvatar, showDeleteUser]);

    const onClickToggleUpdate = (playlistId: number) => {
      dispatch(setPlaylistId(playlistId));
      setShowUpdate(!showUpdate);
    };

    const onClickToggleDelete = (playlistId: number) => {
      dispatch(setPlaylistId(playlistId));
      setShowDelete(!showDelete);
    }

    const onClickToggleAdd = () => {
      setShowAdd(!showAdd);
    };

    const onClickToggleCreate = () => {
      setShowCreate(!showCreate);
    };

    const onClickToggleMediaUpdate = (mediaId: number, mediaPath: string) => {
      dispatch(setOwnMediaId(mediaId));
      dispatch(setOwnMediaPath(mediaPath));
      setShowMediaUpdate(!showMediaUpdate);
    }

    const onClickToggleMediaDelete = (mediaId: number) => {
      dispatch(setOwnMediaId(mediaId));
      setShowMediaDelete(!showMediaDelete);
    }

    const onClickToggleUserUpdate = (email: string) => {
      dispatch(setEmail(email));
      setShowUserUpdate(!showUserUpdate);
    }

    const onClickToggleAvatar = () => {
      setShowAddAvatar(!showAddAvatar);
    }

    const onClickToggleDeleteUser = () => {
      setShowDeleteUser(!showDeleteUser);
    }

    console.log("TESTING from UserProfileView.tsx");

    return (
      <div>
        <HeaderView />
        <div className="user-profile-view">
          {isProfileLoaded && (
            <div>
              <div className="avatar-container">
                <img src={`https://thaikoshamusic.net/uploads/avatars/avatar${userProfile.id}.png`} alt="User Avatar" className="profile-avatar" />
                <FontAwesomeIcon icon={faPlus} className="avatar-overlay-icon" onClick={onClickToggleAvatar} />
                <AddAvatarView isOpen={showAddAvatar} onClickToggle={onClickToggleAvatar} />
            </div>
              <div className={"user-info"}>
                <p>
                  <span className={"user-info-text"}>{userProfile.firstName + ' ' + userProfile.lastName}</span>
                  <FontAwesomeIcon icon={faUserPen}
                    size="lg"
                    className="iconStyle"
                    onClick={() => onClickToggleUserUpdate(userProfile.email)}/>
                        <UpdateUserProfileView
                          isOpen={showUserUpdate}
                          onClickToggle={() => onClickToggleUserUpdate(userProfile.email)}
                        />
                        <FontAwesomeIcon icon={faTrash}
                    size="lg"
                    className="iconStyle"
                    onClick={() => onClickToggleDeleteUser()}/>
                        <DeleteUserView
                          isOpen={showDeleteUser}
                          onClickToggle={() => onClickToggleDeleteUser()}
                        />
                        
                </p>
              </div>
            </div>
          )}
          {isMediaLoaded && allMedia.length > 0 && (
            <div> 
                <p className="section-title">Own Media</p>
                <div className="table-container">
                <table className="table">
                <thead>
                    <tr>
                    <th>Title</th>
                    <th>Artist</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {allMedia.map((media) => (
                    <tr key={media.id}>
                        <td>{media.title}</td>
                        <td>{media.artist}</td>
                        <td>
                        <FontAwesomeIcon icon={faPen} 
                        size="lg"
                        className="iconStyle"
                        onClick={() => onClickToggleMediaUpdate(media.id, media.path)}/>
                        <UpdateMediaView
                          isOpen={showMediaUpdate}
                          onClickToggle={() => onClickToggleMediaUpdate(media.id, media.path)}
                        />
                        <FontAwesomeIcon icon={faTrash} 
                        size="lg"
                        className="iconStyle"
                        onClick={() => onClickToggleMediaDelete(media.id)}/>
                        <DeleteMediaView
                          isOpen={showMediaDelete}
                          onClickToggle={() => onClickToggleMediaDelete(media.id)}
                        />
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>   
                </div>
            </div>
          ) 
        }
        {
            <div>
              <button
              style={{ marginLeft: "1em" }}
              className="action-btn"
              onClick={onClickToggleAdd}>
                Add Media
              </button>
              <AddMediaView
                isOpen={showAdd}
                onClickToggle={onClickToggleAdd}
              />
            </div>
        }
          {isLoaded && ownPlaylists.length > 0 && (
            <div> 
                <p className="section-title">Own Playlists</p>
                <div className="table-container">
                <table className="table">
                <thead>
                    <tr>
                    <th>Name</th>
                    <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {ownPlaylists.map((playlist) => (
                    <tr key={playlist.id}>
                        <td>{playlist.name}</td>
                        <td>
                        <FontAwesomeIcon icon={faGear} 
                        size="lg"
                        className="iconStyle"
                        onClick={() => handleManage(playlist.id)}/>
                        <FontAwesomeIcon icon={faPen} 
                        size="lg"
                        className="iconStyle"
                        onClick={() => onClickToggleUpdate(playlist.id)}/>
                        <UpdatePlaylistView
                          isOpen={showUpdate}
                          onClickToggle={() => onClickToggleUpdate(playlist.id)}
                        />
                        <FontAwesomeIcon icon={faTrash} 
                        size="lg"
                        className="iconStyle"
                        onClick={() => onClickToggleDelete(playlist.id)}/>
                        <DeletePlaylistView
                          isOpen={showDelete}
                          onClickToggle={() => onClickToggleDelete(playlist.id)}
                        />
                        </td>
                    </tr>
                    ))}
                </tbody>
                </table>   
                </div>
            </div>
          ) 
        }
        {
            <div>
              <button 
                style={{ marginLeft: "1em" }}
                className="action-btn"
                onClick={onClickToggleCreate}>
                Add Playlist
              </button>
              <CreatePlaylistView
                isOpen={showCreate}
                onClickToggle={onClickToggleCreate}
              />
            </div>
        }
        </div>
      </div>
      );
    };
    
    export default UserProfileView;