import { handleError } from "../util/error_handler";


export async function addMediatModel(
    title: string,
    artist: string,
    file: File,
    dispatch: any
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/media/add';
    const formData = new FormData();
    formData.append('title', title);
    formData.append('artist', artist);
    formData.append('file', file);

    console.log('file', file);
  try {
    const response = await fetch(url, {
        method : 'POST',
        credentials: 'include',
        body: formData,
    });
    if (!response.ok) {
      handleError({response, dispatch});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to add media', error);
    throw new Error('Failed to add media');
  }
}

export async function getAllMediaModel(): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/media/manage';
  try {
    const response = await fetch(url, {
        method : 'GET',
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to get all media', error);
    throw new Error('Failed to get all media');
  }
}
