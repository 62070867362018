import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../util/store";
import DropDown, {Option} from "react-dropdown";
import { useNavigate } from "react-router-dom";
import "react-dropdown/style.css";

const defaultLabel = "Select a playlist";
const defaultOption = { value: "0", label: defaultLabel };

const PlaylistDropDown = () => {
    const {allPlaylists} = useSelector((state: RootState) => state.playlist);
    const [playlistOptions, setPlaylistOptions] = useState<Array<string | Option>>([defaultLabel]);
    const [selectedOption, setSelectedOption] = useState<Option>(defaultOption);

    useEffect(() => {
        if(allPlaylists) {
            const options = allPlaylists.map((playlist) => {
                return { value: playlist.id, label: playlist.name };
            }
            );
            setPlaylistOptions(options);
            setSelectedOption({
                value: "0",
                label: defaultLabel,
            });
        }
    }, [allPlaylists]);

    const navigate = useNavigate();

    const onChangeDropdown = (option: Option) => {
        setSelectedOption(option);
        if(option.value !== "0") {
            //dispatch(setPlaylistId(parseInt(option.value)));
            navigate(`/playlists/${option.value}`);
        }
    }

    return (
        <div className="playlist-dropdown">
            <DropDown
                options={playlistOptions}
                onChange={onChangeDropdown}
                value={selectedOption}
                placeholder={defaultLabel}
            />
        </div>
    );
}

export default PlaylistDropDown;