import { handleError } from "../util/error_handler";

export async function getUser() {
  const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
  const url: string = `${host}/user/profile`;
  try {
      const response = await fetch(url, {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
      });
      if (!response.ok) {
          const errorBody = await response.text();
          console.error(`Backend returned code ${response.status}, body was: ${errorBody}`);
          handleError({response});
      }
      return await response.json();
  } catch (error) {
      console.error('Failed to fetch user:', error);
      throw new Error('Failed to fetch user');
  }
}

export async function updateUserModel(
    firstName: string, lastName: string, email: string, password: string, dispatch: any
) {
  const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
  const url: string = `${host}/user/update`;
    const payload = {firstName, lastName, email, password};
  try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          credentials: 'include',
          body: JSON.stringify(payload),
      });
      if (!response.ok) {
          const errorBody = await response.text();
          console.error(`Backend returned code ${response.status}, body was: ${errorBody}`);
          handleError({response, dispatch});
      }
      return await response.json();
  } catch (error) {
      console.error('Failed to update user:', error);
      throw new Error('Failed to update user');
  }
}

export async function uploadAvatarModel(
    file: File,
    dispatch: any
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/user/avatar';
    const formData = new FormData();
    formData.append('file', file);

    console.log('file', file);
  try {
    const response = await fetch(url, {
        method : 'POST',
        credentials: 'include',
        body: formData,
    });
    if (!response.ok) {
      handleError({response, dispatch});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to add media', error);
    throw new Error('Failed to add media');
  }
}

export async function deleteUserModel(
): Promise<any> {
    const host: string = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";
    const url: string = '' + host + '/user/delete';
  try {
    const response = await fetch(url, {
        method : 'DELETE',
        credentials: 'include',
    });
    if (!response.ok) {
      handleError({response});
    } else {
      return await response.json();
    }
  } catch (error) {
    console.log('Failed to delete user', error);
    throw new Error('Failed to delete user');
  }
}
