import React, { FC, useEffect } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { deleteUser, logout } from "../viewModel/userSlice";
import { useNavigate } from "react-router-dom";
import "./DeleteUserView.css";
import ModalProps from "../util/ModalProps";

const DeleteUserView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { resultMsg } = useSelector((state: RootState) => state.manage);
  const { isDeleteSuccess } = useSelector((state: RootState) => state.user);
  const message = "Are you sure you want to delete your account? This action cannot be undone.";
  
  useEffect(() => {
    if (isDeleteSuccess) {
      setTimeout(() => {
        window.location.href = '/';
      }
        , 1000);
    }
  }
  , [isDeleteSuccess]);

  const onClickDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(deleteUser());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <div>
        <div className="reg-inputs">
        <p className="modal-message">{message}</p>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              onClick={onClickDelete}
            >
              Confirm
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Cancel
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </div>
    </ReactModal>
  );
};

export default DeleteUserView;
