import React, {useEffect} from "react";
import { useWindowDimensions } from "../util/useWindowDimensions";
import PlaylistDropDown from "./PlaylistDropDown";
import { Trigger } from "../util/component_trigger";
import { RootState } from "../util/store";
import { useSelector } from "react-redux";
import "./MainHeaderView.css";

const MainHeaderView = () => {
    const { width } = useWindowDimensions();
    const { getPlaylistByIdTrigger } = Trigger();
    const { isLoggedIn } = useSelector((state: RootState) => state.user);
    const { playlist } = useSelector((state: RootState) => state.playlist);
    const { playlistId } = useSelector((state: RootState) => state.playlist);

    useEffect(() => {
        getPlaylistByIdTrigger();
      }, [playlistId]);

    const getLabelElement = () => {
        if (width <= 768) {
            return (
                <PlaylistDropDown />
            );
        } else {
            return <strong>{playlist.name || "Select a playlist"}</strong>
    }
};

return (
    isLoggedIn ? (
        <div className="main-header">
        <div
            className="title-bar"
            style={{ marginBottom: ".25em", paddingBottom: "0" }}
            >
                {getLabelElement()}
            </div>
    </div>) : null
    );
};

export default MainHeaderView;