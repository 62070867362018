import React, { FC, } from "react";
import ReactModal from "react-modal";
import ModalProps from "../util/ModalProps";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../util/store";
import {useNavigate} from 'react-router-dom';
import { setPassword, setEmail, login, setResultMsg, setIsSubmitDisabled } from "../viewModel/userSlice";
import { errorPlacer } from "../util/error_handler";

const LoginView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { email, password, isSubmitDisabled, resultMsg, isLoggedIn, backendError } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isLoggedIn) {
     setTimeout(() => {
        dispatch(setResultMsg(""));
        dispatch(setEmail(""));
        dispatch(setPassword(""));
        navigate('/');
     }, 500);
    }
  }, [isLoggedIn]);

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Email cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Password cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onClickLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    onClickToggle(e);
    dispatch(login());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
          <div>
            <label>email</label>
            <input type="text" value={email} onChange={onChangeEmail} />
          </div>
          <strong>
            {errorPlacer("email", backendError)}
          </strong>
          <div>
            <label>password</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={onChangePassword}
            />
          </div>
          <strong>
            {errorPlacer("password", backendError)}
          </strong>
        </div>
        <div className="form-buttons form-buttons-sm">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickLogin}
            >
              Login
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>

          <span className="form-btn-left">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default LoginView;
