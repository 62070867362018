import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../util/store";
import { loadUser, setIsProfileLoaded } from "../viewModel/userSlice";
import { setOwnPlaylistIsLoaded, loadOwnPlaylists, setOwnPlaylistSorting, loadAllPlaylists, setAllPlaylistSorting, setAllPlaylistIsLoaded, loadMediaList, setIsMediaInPlaylistLoaded, setMediaInPlaylistSorting, setIsPlaylistLoadeded, getPlaylistById } from "../viewModel/playlistSlice";
import { setAllMediaIsLoaded, loadAllMedia, setAllMediaSorting } from "../viewModel/mediaSlice";
import { setManageDataLoaded, loadManageData, setMediaSorting, setSharedUsersSorting, setUnsharedUsersSorting, setComplementMediaListSorting, setComplementMediaListLoaded, loadComplementMediaList } from "../viewModel/manageSlice";

const Trigger = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {isLoggedIn} = useSelector((state: RootState) => state.user);
    const {sorting, mediaInPlaylistSorting} = useSelector((state: RootState) => state.playlist);
    const {mediaSorting} = useSelector((state: RootState) => state.media);
    const {sharedUsersSorting, mediaListSorting, unsharedUsersSorting} = useSelector((state: RootState) => state.manage);

    const userProfileTrigger = () => { 
        if (isLoggedIn) {
            dispatch(loadUser());
            dispatch(setIsProfileLoaded(true));
        }
    }

    const ownPlaylistTrigger = () => {
        if (isLoggedIn) {
            dispatch(loadOwnPlaylists())
            .then(() => {
                dispatch(setOwnPlaylistSorting(sorting));
            })
            .then(() => {
                dispatch(setOwnPlaylistIsLoaded(true));
            });

        }
    }

    const manageDataTrigger = () => {
        if (isLoggedIn) {
            dispatch(loadManageData())
            .then(() => {
                dispatch(setMediaSorting(mediaListSorting));
            })
            .then(() => {
                dispatch(setSharedUsersSorting(sharedUsersSorting));
            })
            .then(() => {
                dispatch(setUnsharedUsersSorting(unsharedUsersSorting));
            })
            .then(() => {
                dispatch(setManageDataLoaded(true));
            });
        }
    }

    const complementMediaListTrigger = () => {
        if (isLoggedIn) {
            dispatch(loadComplementMediaList())
            .then(() => {
                dispatch(setComplementMediaListSorting(mediaSorting));
            })
            .then(() => {
                dispatch(setComplementMediaListLoaded(true));
            });
        }
    }

    const allMediaTrigger = () => {
        if (isLoggedIn) {
            dispatch(loadAllMedia())
            .then(() => {
                dispatch(setAllMediaSorting(mediaSorting));
            })
            .then(() => {
                dispatch(setAllMediaIsLoaded(true));
            });
        }
    }

    const allPlaylistTrigger = () => {
        if (isLoggedIn) {
            dispatch(loadAllPlaylists())
            .then(() => {
                dispatch(setAllPlaylistSorting(sorting));
            })
            .then(() => {
                dispatch(setAllPlaylistIsLoaded(true));
            });
        }
    }

    const loadMediaInPlaylistTrigger = () => {
        if (isLoggedIn) {
            dispatch(loadMediaList())
            .then(() => {
                dispatch(setMediaInPlaylistSorting(mediaInPlaylistSorting));
            })
            .then(() => {
                dispatch(setIsMediaInPlaylistLoaded(true));
            });
        }
    }

    const getPlaylistByIdTrigger = () => {
        if (isLoggedIn) {
            dispatch(getPlaylistById())
            .then(() => {
                dispatch(setIsPlaylistLoadeded(true));
            });
        }
    }

    return {
        userProfileTrigger,
        ownPlaylistTrigger,
        allPlaylistTrigger,
        allMediaTrigger,
        manageDataTrigger,
        complementMediaListTrigger,
        loadMediaInPlaylistTrigger,
        getPlaylistByIdTrigger,
    }

}

export {Trigger};