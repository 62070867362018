import { PayloadAction } from "@reduxjs/toolkit";
import { MediaState } from "./mediaSlice";

export const mediaTitleReducer = (
    state: MediaState,
    action: PayloadAction<string>
) => {
    state.title = action.payload;
};

export const mediaArtistReducer = (
    state: MediaState,
    action: PayloadAction<string>
) => {
    state.artist = action.payload;
};

export const mediaFileReducer = (
    state: MediaState,
    action: PayloadAction<File>
) => {
    state.file = action.payload;
};

export const mediaPathReducer = (
    state: MediaState,
    action: PayloadAction<string>
) => {
    state.path = action.payload;
}

export const sortingAllMediaReducer = (
    state: MediaState,
    action: PayloadAction<'a-z'>
) => {
   state.allMedia.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }
        return 0;
   });
   state.mediaSorting = action.payload;
};

export const isLoadedReducer = (
    state: MediaState,
    action: PayloadAction<boolean>
) => {
    state.isMediaLoaded = action.payload;
};

export const getAllReducer = (
    state: MediaState,
    action: any
) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.allMedia = action.payload.data[0].medias;
    } else {
        state.error = action.payload.message;
    }
};


export const addMediaReducer = (state: MediaState, action: any) => {
    if(!action.payload) return;
    if(action.payload.success) {
        state.resultMsg = action.payload.data;
        state.isMediaAdded = true;
    } else {
        state.error = [action.payload.error.message];
    }
}

export const resultMsgReducer = (
    state: MediaState,
    action: PayloadAction<string>
  ) => {
    state.resultMsg = action.payload;
};

export const isSubmitDisabledReducer = (
    state: MediaState,
    action: PayloadAction<boolean>
  ) => {
    state.isSubmitDisabled = action.payload;
  };
