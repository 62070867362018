import React, { FC, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../util/store";
import { setOwnMediaTitle, setOwnMediaArtist, setResultMsg, setIsSubmitDisabled, updateMedia } from "../viewModel/manageSlice";
import "./UpdateMediaView.css";
import ModalProps from "../util/ModalProps";

const UpdateMediaView: FC<ModalProps> = ({ isOpen, onClickToggle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { ownMediaTitle, ownMediaArtist, isSubmitDisabled, resultMsg, isMediaUpdated } = useSelector((state: RootState) => state.manage);

  useEffect(() => {
    if (isMediaUpdated) {
      setTimeout(() => {
        dispatch(setResultMsg(""));
        dispatch(setIsSubmitDisabled(true));
        dispatch(setOwnMediaTitle(""));
        dispatch(setOwnMediaArtist(""));
      }, 500);
    }
  }, [isMediaUpdated, dispatch]);

  const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOwnMediaTitle(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Title cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onChangeArtist = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setOwnMediaArtist(e.target.value));
    if (!e.target.value) {
      dispatch(setIsSubmitDisabled(true));
      dispatch(setResultMsg("Artist cannot be empty"));
    }
    else dispatch(setIsSubmitDisabled(false));
  };

  const onClickUpdate = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    dispatch(updateMedia());
  };

  const onClickCancel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClickToggle(e);
  };

  return (
    <ReactModal
      className="modal-menu"
      isOpen={isOpen}
      onRequestClose={onClickToggle}
      shouldCloseOnOverlayClick={true}
    >
      <form>
        <div className="reg-inputs">
        <div>
            <label>New Title</label>
            <input type="text" value={ownMediaTitle} onChange={onChangeTitle} />
          </div>
        </div>
        <div className="reg-inputs">
        <div>
            <label>New Artist</label>
            <input type="text" value={ownMediaArtist} onChange={onChangeArtist} />
          </div>
        </div>
        <div className="form-buttons">
          <div className="form-btn-left">
            <button
              style={{ marginLeft: ".5em" }}
              className="action-btn"
              disabled={isSubmitDisabled}
              onClick={onClickUpdate}
            >
              Update
            </button>
            <button
              style={{ marginLeft: ".5em" }}
              className="cancel-btn"
              onClick={onClickCancel}
            >
              Close
            </button>
          </div>
          <span className="form-btn-right">
            <strong>{resultMsg}</strong>
          </span>
        </div>
      </form>
    </ReactModal>
  );
};

export default UpdateMediaView;
