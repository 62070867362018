import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { mediaTitleReducer, mediaArtistReducer, mediaFileReducer, mediaPathReducer, sortingAllMediaReducer, isLoadedReducer, getAllReducer, addMediaReducer, resultMsgReducer, isSubmitDisabledReducer } from './mediaReducer';
import { addMediatModel } from '../model/mediaModel';
import { getAllMediaModel } from '../model/mediaModel';

export interface MediaState {
    title: string;
    artist: string;
    file: File | null;
    path: string;
    allMedia: any[];
    mediaSorting: 'a-z';
    isMediaLoaded: boolean;
    resultMsg: string;
    error: string[];
    isSubmitDisabled: boolean;
    isMediaAdded: boolean;
}

const initialState: MediaState = {
    title: '',
    artist: '',
    file: null,
    path: '',
    allMedia: [],
    mediaSorting: 'a-z',
    isMediaLoaded: false,
    resultMsg: '',
    error: [],
    isSubmitDisabled: true,
    isMediaAdded: false,
};

export const MediaSlice = createSlice({
    name: 'playlist',
    initialState,
    reducers: {
        setTitle: mediaTitleReducer,
        setArtist: mediaArtistReducer,
        setFile: mediaFileReducer,
        setPath: mediaPathReducer,
        setAllMediaSorting: sortingAllMediaReducer,
        setAllMediaIsLoaded: isLoadedReducer,
        setResultMsg: resultMsgReducer,
        setIsSubmitDisabled: isSubmitDisabledReducer,
    },
    extraReducers: (builder) => {
        builder.addCase(addMedia.fulfilled, addMediaReducer);
        builder.addCase(loadAllMedia.fulfilled, getAllReducer);
    },
});

export const addMedia = createAsyncThunk(
    'media/add',
    async (arg, {getState, dispatch}) => {
        const state = getState() as {media: MediaState};
        const {title, artist, file} = state.media;
        if (file && state.media.error.length === 0) {
            return await addMediatModel(
                title,
                artist,
                file,
                dispatch
            );
        } else return false;
    }
);


export const loadAllMedia = createAsyncThunk(
    'media/manage',
    async () => {
        return getAllMediaModel();
    }
);

export const { setAllMediaSorting, setAllMediaIsLoaded, setTitle, setArtist, setFile, setPath, setIsSubmitDisabled, setResultMsg } = MediaSlice.actions;
export default MediaSlice.reducer;
